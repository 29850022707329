import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import { getRowsAndTickersSelected } from "~/functions/common";
import { createCurrencyFormatter } from "~/utils/valuationTransform/peModel";
export default defineComponent({
  props: {
    dataseries: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    dateheaders: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    columnunits: {
      type: String,
      "default": "MM"
    },
    columnisocode: {
      type: String,
      "default": "USD"
    },
    columndecimals: {
      type: Number,
      "default": 1
    },
    rightylabel: {
      type: String,
      "default": "%"
    },
    showchartlabels: {
      type: Boolean,
      "default": false
    },
    charttitle: {
      type: String,
      "default": ""
    },
    period: {
      type: String,
      "default": "a"
    }
  },
  setup: function setup(props) {
    var _useCiqTableOptions = useCiqTableOptions(),
      reverseDates = _useCiqTableOptions.reverseDates;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var chartDescription = computed(function () {
      var _getRowsAndTickersSel = getRowsAndTickersSelected({
          $store: $store,
          chartType: "financials",
          period: props.period
        }),
        rows = _getRowsAndTickersSel.rows,
        tickers = _getRowsAndTickersSel.tickers; // en-GB has no oxford comma at the end
      var list = new Intl.ListFormat("en-GB");
      return "".concat(list.format(tickers), "'s ").concat(list.format(rows));
    });
    return {
      chartDescription: chartDescription,
      reverseDates: reverseDates
    };
  },
  data: function data() {
    return {
      fullscreen: false,
      chartHeight: "400"
    };
  },
  computed: {
    tikrOptions: function tikrOptions() {
      var vue = this;
      var darkMode = this.darkMode;
      var rightylabel = this.rightylabel;
      var decimals = this.columndecimals;
      var showChartLabels = this.showchartlabels;
      var chartTitle = this.charttitle;
      var dateHeaders = this.dateheaders.length ? this.dateheaders : [];
      var formatDate = this.formatDate;
      var fullscreen = this.fullscreen;
      var chartHeight = this.chartHeight;
      var series = this.dataseries;
      var onlyPercent = series.reduce(function (acc, i) {
        if (i.yAxis === 0) {
          return false;
        } else {
          return acc;
        }
      }, true);
      var onlyPriceFactor = series.reduce(function (acc, i) {
        if (i.yAxis === 3) {
          return false;
        } else {
          return acc;
        }
      }, true);
      var onlyIdxFour = series.reduce(function (acc, i) {
        if (i.yAxis === 4) {
          return false;
        } else {
          return acc;
        }
      }, true);
      var amountOfZeroIdxItems = series.filter(function (item) {
        return item.xAxis === 0;
      }).length;
      var defaultTikrOptions = {
        chart: {
          height: fullscreen ? null : vue.$vuetify.breakpoint.name !== "xs" ? chartHeight : null,
          zooming: {
            mouseWheel: false
          }
        },
        colors: ["#7cb5ec", darkMode ? "#e6e6e6" : "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
        accessibility: {
          description: this.chartDescription
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: showChartLabels,
              style: {
                color: darkMode ? "#e6e6e6" : "#333333",
                textOutline: "1px contrast",
                fontWeight: "bold"
              }
            }
          },
          column: {
            dataLabels: {
              enabled: showChartLabels,
              style: {
                color: darkMode ? "#e6e6e6" : "#333333",
                textOutline: "1px contrast",
                fontWeight: "bold"
              }
            }
          },
          series: {
            dataGrouping: {
              enabled: true
            }
          }
        },
        title: {
          text: chartTitle,
          style: {
            color: darkMode ? "#e6e6e6" : "#333333",
            fontSize: "16px"
          }
        },
        legend: {
          enabled: true,
          itemHiddenStyle: {
            color: darkMode ? "#2F3033" : "#cccccc"
          },
          itemStyle: {
            color: darkMode ? "#e6e6e6" : "#333333",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: "bold",
            textOverflow: "ellipsis"
          }
        },
        tooltip: {
          valueDecimals: decimals,
          dateTimeLabelFormats: {
            day: "%b %e %Y"
          },
          pointFormatter: function pointFormatter() {
            try {
              var y = this.y;
              var name = this.series.name;
              var color = this.series.color;
              var yAxis = this.series.userOptions.yAxis;
              var displayCurrency = [2, 3].includes(yAxis);
              if (displayCurrency) {
                var _this$series$userOpti;
                var isocode = (_this$series$userOpti = this.series.userOptions) === null || _this$series$userOpti === void 0 ? void 0 : _this$series$userOpti.iso;
                var _formatter = vue.formatCurrency(isocode).format;
                return "<span style=\"color:".concat(color, "\">\u25CF</span> ").concat(name, ": <b>").concat(_formatter(y), "</b><br/>");
              }
              var formatter = vue.formatNumber.format;
              return "<span style=\"color:".concat(color, "\">\u25CF</span> ").concat(name, ": <b>").concat(formatter(y), "</b><br/>");
            } catch (error) {
              console.error("pointFormatter error: ", error);
              var _y = this.y;
              var _name = this.series.name;
              var _color = this.series.color;
              return "<span style=\"color:".concat(_color, "\">\u25CF</span> ").concat(_name, ": <b>").concat(_y, "</b><br/>");
            }
          }
        },
        xAxis: [{
          reversed: this.reverseDates,
          type: "datetime",
          dateTimeLabelFormats: {
            day: "%m/%e/%Y",
            month: "%m/%e/%y",
            year: "%Y"
          },
          tickPositioner: function tickPositioner() {
            try {
              // determine if the tickPostions need to be adjusted due to difference in
              // a companies fiscal year from the calendar year
              // if they're the same, adjust the tick positions back one day to
              // end of the month instead of beginning of the next month
              if (dateHeaders) {
                // for each tick position find the dateHeader that is closest
                var closestDate = this.tickPositions.map(function (t) {
                  var realClose = dateHeaders.reduce(function (acc, d) {
                    var distance = d.timeVal - t;
                    var absDistance = Math.abs(distance);
                    if (acc.distance) {
                      if (absDistance <= acc.absDistance) {
                        return Object.assign({}, d, {
                          distance: distance,
                          absDistance: absDistance
                        });
                      } else {
                        return acc;
                      }
                    } else {
                      return Object.assign({}, d, {
                        distance: distance,
                        absDistance: absDistance
                      });
                    }
                  }, {});
                  return realClose;
                });
                var distance = closestDate.map(function (d) {
                  var distance = d.distance,
                    absDistance = d.absDistance;
                  return {
                    distance: distance,
                    absDistance: absDistance
                  };
                });
                var tickPositions = this.tickPositions;
                var middleDistance = distance.filter(function (dis, i) {
                  return i !== 0 && i !== distance.length - 1;
                }).map(function (d) {
                  return d.distance;
                });
                var middleSum = middleDistance.reduce(function (acc, d) {
                  return acc + d;
                }, 0);
                var middleAvg = middleSum / middleDistance.length;
                var totalRange = tickPositions.info.totalRange;
                var highDistance = middleAvg + totalRange / 3;
                var lowDistance = middleAvg - totalRange / 3;
                for (var i = 0; i < tickPositions.length; i++) {
                  // check if the tickDistance adjustment is within half of the total range
                  if (i === 0 || i === tickPositions.length - 1) {
                    var tickDistance = distance[i].distance;
                    if (tickDistance < highDistance && tickDistance > lowDistance) {
                      tickPositions[i] = tickPositions[i] + distance[i].distance;
                    } // figure out if you should adjust this or not
                  } else {
                    tickPositions[i] = tickPositions[i] + distance[i].distance;
                  }
                }
                return this.tickPositions;
              } else {
                return this.tickPositions;
              }
            } catch (err) {
              console.error("error w/ tick positioner: ", err);
            }
          },
          labels: {
            formatter: function formatter() {
              try {
                // this.value is the epoch
                var date = new Date(this.value);
                return formatDate.format(date);
              } catch (error) {
                console.log("TableChart label formatter error: ", error);
              }
            },
            style: {
              color: this.darkMode ? "#e6e6e6" : "#666666"
            }
          }
        }, {
          id: "daily",
          type: "datetime",
          // unlink daily axis if theres no data being displayed
          linkedTo: amountOfZeroIdxItems === 0 ? null : 0,
          lineWidth: 0,
          labels: {
            enabled: false
          },
          tickWidth: 0,
          visible: false
        }],
        yAxis: [{
          opposite: false,
          gridLineColor: this.darkMode ? "#969696" : "#e6e6e6",
          labels: {
            style: {
              color: darkMode ? "#e6e6e6" : "#666666"
            },
            alignTicks: false
          }
        }, {
          opposite: !onlyPercent,
          labels: {
            formatter: function formatter() {
              return "".concat(this.value).concat(rightylabel);
            },
            style: {
              color: darkMode ? "#e6e6e6" : "#666666"
            }
          },
          alignTicks: false,
          gridLineWidth: onlyPercent ? 1 : 0
        }, {
          opposite: true,
          gridLineColor: this.darkMode ? "#969696" : "#e6e6e6",
          labels: {
            style: {
              color: darkMode ? "#e6e6e6" : "#666666"
            }
          },
          alignTicks: false,
          gridLineWidth: onlyPriceFactor ? 1 : 0
        }, {
          opposite: true,
          gridLineColor: this.darkMode ? "#969696" : "#e6e6e6",
          labels: {
            style: {
              color: darkMode ? "#e6e6e6" : "#666666"
            }
          },
          alignTicks: false,
          gridLineWidth: onlyIdxFour ? 1 : 0
        }],
        stockTools: {
          gui: {
            enabled: false
          }
        },
        credits: {
          enabled: true,
          href: "https://tikr.com",
          text: "TIKR.com",
          style: {
            cursor: "pointer",
            color: darkMode ? "#FFFFFF" : "#2F3033",
            fontSize: "15px"
          }
        },
        rangeSelector: {
          enabled: false,
          buttonTheme: {
            fill: "#E1E4EA",
            r: 5,
            style: {
              color: "black"
            },
            states: {
              hover: {
                fill: this.$vuetify.theme.themes.light.primaryAction,
                style: {
                  color: "black"
                }
              },
              select: {
                fill: this.$vuetify.theme.themes.light.primaryAction,
                style: {
                  color: "black"
                }
              }
            }
          },
          inputEnabled: false
        },
        navigator: {
          enabled: false,
          maskFill: "rgba(255, 153, 0, 0.4)",
          handles: {
            backgroundColor: this.chartColor,
            borderColor: "#232F3E",
            symbols: ["customcircle", "customcircle"]
          }
        },
        scrollbar: {
          enabled: false,
          barBackgroundColor: "#E1E4EA",
          barBorderRadius: 7,
          barBorderWidth: 0,
          buttonBackgroundColor: "#E1E4EA",
          buttonBorderWidth: 0,
          buttonArrowColor: "#232F3E",
          buttonBorderRadius: 7,
          rifleColor: "#232F3E",
          trackBackgroundColor: "white",
          trackBorderWidth: 1,
          trackBorderColor: "#828282",
          trackBorderRadius: 7
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"],
              symbolFill: this.darkMode ? "#a5a5a5" : "#666666",
              symbolStroke: this.darkMode ? "#a5a5a5" : "#666666",
              theme: {
                fill: this.darkMode ? "#1E1E1E" : "#ffffff"
              }
            }
          },
          sourceWidth: 1000,
          sourceHeight: 600,
          scale: 3
        }
      };
      if (darkMode) {
        defaultTikrOptions.chart.backgroundColor = "#1E1E1E";
      }
      return Object.assign({}, defaultTikrOptions, {
        series: series
      });
    },
    availableSeries: function availableSeries() {
      return [{}, {}];
    },
    chartColor: function chartColor() {
      return this.$store.state.chartColor;
    },
    darkMode: function darkMode() {
      return this.$vuetify.theme.dark;
    },
    formatDate: function formatDate() {
      var lang = navigator.language || "default";
      return new Intl.DateTimeFormat(lang, {
        timeZone: "UTC",
        year: "2-digit",
        month: "numeric",
        day: "numeric"
      });
    },
    formatNumber: function formatNumber() {
      var lang = navigator.language || "default";
      var decimals = this.columndecimals;
      return new Intl.NumberFormat(lang, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    },
    formatPercent: function formatPercent() {
      var lang = navigator.language || "default";
      var decimals = this.columndecimals;
      return new Intl.NumberFormat(lang, {
        style: "percent",
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    }
  },
  created: function created() {
    var _this = this;
    document.addEventListener("fullscreenchange", function () {
      _this.fullscreen = Boolean(document.fullscreenElement);
    });
  },
  methods: {
    formatCurrency: function formatCurrency(isoStr) {
      var lang = navigator.language || "default";
      return createCurrencyFormatter({
        minDigits: 2,
        maxDigits: 2,
        isocode: isoStr,
        lang: lang
      });
    }
  }
});